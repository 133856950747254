import { getToken } from "../redux/auth/authActions";

// params: Object of key:value pairs for query parameters, excluding
// includes.
// includes: Array of included relations.
export function assembleQuery(includes = [], params = {}) {
  const searchParamsData = { ...params };
  if (includes.length > 0) {
    searchParamsData.include = includes.join(",");
  }
  const string = new URLSearchParams(searchParamsData).toString();
  return string;
}

// Creates an authenticated request. Requires `dispatch` to keep `auth` key in
// sync with Redux store.
// Request object can be extended by fetchers / mutators to set e.g. method,
// body, or other headers.
export async function createAuthenticatedRequest(
  path,
  dispatch,
  options = {},
  inferContentType = false
) {
  const { includes, params, headers, ...requestOptions } = options;
  const token = await dispatch(getToken());

  const requestHeaders = {
    Authorization: `Bearer ${token}`,
    ...headers,
  };

  if (!inferContentType) {
    requestHeaders["Content-Type"] = "application/json; charset=utf-8";
  }

  return new Request(
    `${process.env.REACT_APP_API_HOST}api/${path}?${assembleQuery(
      includes,
      params
    )}`,
    {
      headers: requestHeaders,
      ...requestOptions,
    }
  );
}

// Simple helper for running & deserializing a request. Lets us keep request & error
// deserialization in 1 spot. Useful if we want to e.g. deserialize all dates.
export async function executeRequest(request) {
  const res = await fetch(request);

  // If the response is not JSON, we can just return it
  let isJSON = false;
  let json;
  try {
    json = await res.json();
    isJSON = true;
  } catch (err) {
    // Not JSON
  }

  if (!res.ok) {
    // If the response is JSON, we can return the error message
    if (isJSON) {
      throw new Error(json.message || res.statusText);
    }
    // Otherwise, just return the status text
    throw new Error(res.statusText);
  }

  // Request was successful
  return isJSON ? json : res;
}

// Default react-query options. Override per-request if necessary.
export const defaultQueryOptions = {
  // Retry 3 times on network error
  retry: 3,

  // Whether we should keep previous data while fetching fresh
  keepPreviousData: true,

  // Consider data stale after 15 seconds. See https://tanstack.com/query/v4/docs/react/guides/important-defaults
  // It will get refetched if at least 15 seconds have passed on any of these events:
  //  * New instances of the query mount
  //  * The window is refocused
  //  * The network is reconnected
  staleTime: 15 * 1000,

  // Other useful options:
  // Control retry delay. Exponential backoff by default
  // retryDelay: 1000,

  // Refetch every second
  // refetchInterval: 1000,

  // Disable this query if, e.g. validation is not completed.
  // enabled: false,

  // onSuccess / onError / onSettled can be use to sync data with e.g. Redux
};

export const defaultMutationOptions = {
  // Default options here
};
