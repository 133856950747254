import React, { useState } from "react";
import { array, bool, func, string } from "prop-types";
import { Form, Select, Input, Button } from "antd";
import TwoComponentSwitch from "../../shared/TwoComponentSwitch/TwoComponentSwitch";
import styles from "./CompanyForm.module.css";
import {
  defaultButtonWrapperCol,
  defaultFormItemLayout,
} from "../../../utils/formLayout";
import ImageUpload from "../../shared/ImageUpload";
import { camelize } from "../../../utils/stringUtils";
import MoneyInput from "../../shared/MoneyInput";

const { TextArea } = Input;

const FirstForm = ({ options, name, loading }) => {
  return (
    <Form.Item
      label="Company Name"
      className={styles.formItem}
      name={name}
      rules={[{ required: true, message: "required" }]}
    >
      <Select
        loading={loading}
        showSearch
        options={options}
        className={styles.fullWidth}
        filterOption={(inputValue, option) => {
          return (
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          );
        }}
        placeholder="Type to search companies"
      />
    </Form.Item>
  );
};

FirstForm.propTypes = {
  options: array,
  name: string,
  loading: bool,
};

const SecondForm = ({
  toggleVisibleComponent,
  options,
  setOptions,
  setSelectedOption,
}) => {
  const [form] = Form.useForm();
  const [companyName, setCompanyName] = useState("");

  const onSubmit = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue(true);
      const newOption = {
        label: values.name,
        value: JSON.stringify(values),
      };
      setOptions([newOption, ...options]);
      setSelectedOption(newOption.value);
      toggleVisibleComponent();
    } catch (error) {
      //ignore since ant design will handle validation based on form.item rules
    }
  };

  const validateCompanyName = value => {
    const existingOption = options.find(o => o.value === value);
    if (!value) {
      return Promise.reject(new Error("required"));
    }
    if (!value.trim()) {
      return Promise.reject(new Error("company name can't be all spaces"));
    }
    if (existingOption) {
      return Promise.reject(new Error("company name already exists"));
    }
    return Promise.resolve();
  };

  const onUploadImage = urls => {
    const logoUrl = urls.fullImageUrl.endsWith("png")
      ? urls.fullImageUrl
      : urls.pngImageUrl;

    form.setFieldsValue({
      logoUrl,
    });
  };

  return (
    <Form
      component={false}
      {...defaultFormItemLayout}
      labelAlign="right"
      layout="horizontal"
      form={form}
    >
      <Form.Item
        label="Company Name"
        name="name"
        rules={[
          {
            required: true,
            validator: (rule, value) => validateCompanyName(value),
          },
        ]}
      >
        <Input onChange={e => setCompanyName(e.target.value.trim())} />
      </Form.Item>
      <Form.Item
        label="Company IG Handle"
        name="igHandle"
        rules={[
          {
            required: true,
            message: "required",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Company Description"
        name="description"
        rules={[{ required: true, type: "string", max: 400 }]}
      >
        <TextArea rows={4} />
      </Form.Item>
      {companyName && (
        <>
          <Form.Item
            label="Logo Image"
            name="logoUrl"
            rules={[{ required: true, message: "required" }]}
          >
            <ImageUpload
              altText="company logo"
              imageName={`${camelize(companyName)}_logo`}
              onUpload={onUploadImage}
              useThumbnailPreview={false}
              containerName="company-images"
              variants={{
                png: {
                  fileType: "png",
                  resize: [500, 500, { fit: "inside" }],
                },
                // This is actually redundant because we default fullImageUrl to webp
                // but I'm leaving it for now to keep this call consistent with the self
                // serve.
                webp: {
                  fileType: "webp",
                  resize: [500, 500, { fit: "inside" }],
                },
              }}
            />
          </Form.Item>
        </>
      )}
      <Form.Item
        label="Website"
        name="website"
        rules={[
          {
            type: "url",
            message: "This field must be a valid url.",
          },
        ]}
      >
        <Input defaultValue={null} />
      </Form.Item>
      <Form.Item label="Revenue" name="revenue">
        <MoneyInput
          defaultValue={null}
          formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          parser={value => value.replace(/\$\s?|(,*)/g, "")}
          stringMode={false}
        />
      </Form.Item>
      <Form.Item wrapperCol={defaultButtonWrapperCol}>
        <Button onClick={onSubmit}>Submit</Button>
      </Form.Item>
    </Form>
  );
};

SecondForm.propTypes = {
  options: array,
  setSelectedOption: func,
  toggleVisibleComponent: func,
  setOptions: func,
};

function CompanyForm({
  options,
  setOptions,
  setSelectedOption,
  name,
  loading,
  onSwitch,
}) {
  return (
    <TwoComponentSwitch
      className={styles.fullWidth}
      activateFirstText="Select Existing Company"
      activateSecondText="Create New Company"
      onSwitch={onSwitch}
      firstComponent={
        <FirstForm
          options={options}
          setSelectedOption={setSelectedOption}
          name={name}
          loading={loading}
        />
      }
      secondComponent={
        <SecondForm
          options={options}
          setOptions={setOptions}
          setSelectedOption={setSelectedOption}
        />
      }
    />
  );
}

CompanyForm.propTypes = {
  options: array,
  setOptions: func,
  setSelectedOption: func,
  name: string,
  loading: bool,
  onSwitch: func,
};

export default CompanyForm;
